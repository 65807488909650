jQuery(document).ready( function( $ ){

  const $checkedProductsList = $(document).find(".checked-products-list");
  const $noSelectedMessage   = $(document).find(".add-ons-slider-checked-products-no-selected");
  const addOnsSelectedList   = $(document).find(".add-ons-selected-list-placeholder");
  
  const UPSELL_PRODUCTS = {

    init: function(){

      $(document)
        .on( 'click', '.addon-product-checkbox-button', this.addonProductAdd )
        .on( 'click', ".addon-product .cross-sell-attribute", this.toggleAddonCartButton )
        .on( 'change', ".addon-product select.cross-sell-attribute-select", this.toggleAddonCartButton )
        .on( 'click', ".sidebar-slide-inner-filter .button", this.toggleAddonFilter )
        .on( 'click', '.remove-checked-product, .addon-remove', this.addonProductRemove )
        .on( 'input', '.cross-sell-quantity', this.addonCrossSellAddToCartDataUpdate )
        .on( 'found_variation', this.hideShowFilters );

    },

    /*hideShowFilters: function (event, variation) {
      console.log('Variation has been changed');
    },*/

    toggleAddonCartButton: function(){

      var addon_product  = $(this).parents('.addon-product');
      var variationInput = addon_product.find(".cross_sell_variation_id");
      var all_selected   = true;

      if (variationInput.length) {
          // Group radio and select attributes
          var attributeGroups    = {};
          var selectedAttributes = {};

          // Collect radio attributes
          addon_product.find(".cross-sell-attribute").each(function() {
              var attributeName = $(this).data("attribute_name");

              // Initialize group if not already created
              if (!attributeGroups[attributeName]) {
                  attributeGroups[attributeName] = [];
              }

              if( $(this).is(':checked') ){
                selectedAttributes["attribute_" + attributeName] = $(this).val();
              }

              // Add the current radio button to the group
              attributeGroups[attributeName].push(this);
          });

          // Check if at least one radio button is selected in each group
          $.each(attributeGroups, function(attributeName, elements) {
              var isChecked = elements.some((element) => $(element).is(":checked"));
              if (!isChecked) {
                  console.log('Missing selection for attribute:', attributeName);
                  all_selected = false;
              }
          });

          // Check selected dropdown attributes
          addon_product.find("select.cross-sell-attribute-select").each(function() {
              if (!$(this).val()) {
                console.log('Dropdown not selected for attribute:', $(this).data("attribute_name"));
                all_selected = false;
              }else{
                selectedAttributes["attribute_" + $(this).data("attribute_name")] = $(this).val();
              }
          });

        if (all_selected) {

          var matchingVariation = UPSELL_PRODUCTS.findMatchingVariation( addon_product, selectedAttributes );
         console.log(matchingVariation);
          if( matchingVariation ){

            UPSELL_PRODUCTS.toggleAddonMedia( matchingVariation.variation_id );
             
            if( matchingVariation.is_in_stock ){
              addon_product.find('.addon-product-checkbox-button').removeClass('disabled'); // Enable button
              addon_product.find('.addon-product-availability-html').html( '' ); // Enable button
            }else{
              addon_product.find('.addon-product-checkbox-button').addClass('disabled');
              addon_product.find('.addon-product-availability-html').html( matchingVariation.availability_html ); // Enable button
            }
          }else{
            addon_product.find('.addon-product-checkbox-button').addClass('disabled');
          } 
        }
      }
    },

    toggleAddonMedia: function( variation_id ){
      window.dispatchEvent(new CustomEvent("crossSellVariationChange", { detail: { variation_id: variation_id } }));
    },

    toggleAddonSelectedList: function(){

      var addon_list  = addOnsSelectedList.find('.add-ons-selected-list-item');
      if ( addon_list.length ) {
        addOnsSelectedList.parents('.add-ons-selected-list').show();
        $noSelectedMessage.hide();
      }else{
        addOnsSelectedList.parents('.add-ons-selected-list').hide();
        $noSelectedMessage.show();
      }

    },

    toggleAddonFilter: function( e ){
      e.preventDefault();

      var $clickedButton = $(this);
      var $buttons       = $(document).find('.sidebar-slide-inner-filter .button');
      var filterType     = $clickedButton.hasClass("create-products-filter") ? "one" : "two";
      var $products      = $(document).find('.addon-product');

      // Toggle active class
      if ($clickedButton.hasClass("active-tab")) {
        $buttons.removeClass("active-tab");
        $products.removeClass("hidden");
      } else {
        $buttons.removeClass("active-tab");
        $clickedButton.addClass("active-tab");

        $products.each(function () {
          var $product = $(this);
          var productSource = $product.data("source");

          if (productSource === filterType) {
            $product.removeClass("hidden");
          } else {
            $product.addClass("hidden");
          }
        });
      }
      // Find the first visible product and trigger the gallery update
      var $firstVisibleProduct = $products.not(".hidden").first();
      if ($firstVisibleProduct.length) {
        $firstVisibleProduct.find(".addon-product-image").trigger("click");
      }
    },

    addonProductAdd: function( e ) {
      e.preventDefault();

     //Product fields
      const product_id        = $(this).attr('product-id');
      const addon_product     = UPSELL_PRODUCTS.getAddonProductData( product_id );
      var variationInput      = addon_product.find(".cross_sell_variation_id");
      var quantity            = addon_product.find(".quantity__input").val();
      var productName         = addon_product.data("product-name");
      var productPriceStriped = addon_product.data("product-price");
      var productPrice        = addon_product.find(".woocommerce-Price-amount").text();
      var variation_id        = '';

      if( variationInput ){
        
        var selectedAttributes = {};

        // Get selected radio attributes
        addon_product.find(".cross-sell-attribute:checked").each(function () {
          var attributeName = $(this).data("attribute_name");
          selectedAttributes["attribute_" + attributeName] = $(this).val();
        });

        // Get selected dropdown attributes
        addon_product.find("select.cross-sell-attribute-select").each(function () {
          var attributeName = $(this).data("attribute_name");
          var selectedValue = $(this).val();
          if (selectedValue) {
            selectedAttributes["attribute_" + attributeName] = selectedValue;
          }
        });

        const matchingVariation = UPSELL_PRODUCTS.findMatchingVariation( addon_product, selectedAttributes );
        const priceContainer    = addon_product.find(".addon-product-top-right-price-quantity .woocommerce-Price-amount");

        if (matchingVariation) {

          console.log("Matching variation found:", matchingVariation);

          variation_id        = matchingVariation.variation_id;
          let variation_price = matchingVariation.price_html;

          // Update price
          priceContainer.html(variation_price);

          // Update variation_id input
          variationInput.val(variation_id);

          productPrice        = variation_price;
          productPriceStriped = matchingVariation.display_price;

          //Update the product gallery
          UPSELL_PRODUCTS.toggleAddonMedia( variation_id );

        }
        
      }

      var variationName = variationInput.val() ? ` / ${addon_product.find(".cross-sell-attribute:checked").val() || addon_product.find("select.cross-sell-attribute-select option:selected").text()}` : "";
      
      /*
      * @ Inside slide in selected list
      */
      var checkedProductHtml = `
      <span class="checked-product add-ons-slider-checked-products-item" data-variation-id="${variation_id}" data-product-id="${product_id}">
        ${productName}<span class="variation-name">${variationName}</span>
        <button class="remove-checked-product" data-product-id="${product_id}">×</button>
      </span>`;

      /*
       * @ Under button
       */
      var addOnItemHtml = `
            <div class="add-ons-selected-list-item" data-variation-id="${variation_id}" data-product-price="${productPriceStriped}" data-product-quantity="${quantity}" data-product-id="${product_id}">
              <span class="addon-title">${productName}${variationName}</span>
              <span class="addon-price">${productPrice}</span>
              <span class="addon-quantity"><input type="number" class="cross-sell-quantity" value="${quantity}" ></span>
              <button class="addon-remove" data-product-id="${product_id}"></button>
            </div>`;

      $checkedProductsList.append(checkedProductHtml);
      addOnsSelectedList.append(addOnItemHtml);

      UPSELL_PRODUCTS.toggleAddonSelectedList();
      UPSELL_PRODUCTS.addonCrossSellAddToCartDataUpdate();
      window?.quantity_product_price_update();

    },

    addonProductRemove: function( e ) {
      e.preventDefault();

      var $closestItem = $(this).closest('.add-ons-selected-list-item, .checked-product');
      var index        = $closestItem.index();

      // Remove the visual element
      if ($closestItem.hasClass('add-ons-selected-list-item')) {
          $closestItem.remove();
          $('.checked-product').eq(index).remove();
      } else if ($closestItem.hasClass('checked-product')) {
          $closestItem.remove();
          $('.add-ons-selected-list-item').eq(index).remove();
      }

      UPSELL_PRODUCTS.toggleAddonSelectedList();
      UPSELL_PRODUCTS.addonCrossSellAddToCartDataUpdate();
    
    },

    /**
     * This function is used to update the addon products and the variations quantity
     * @param {*} e 
     * @returns 
     */
    addonCrossSellAddToCartDataUpdate: function () {

      var addon_item_list = $(document).find('.add-ons-selected-list-item');
      
      window?.quantity_product_price_update();

      if( !addon_item_list.length ){
        return;
      }

      var productIds    = []; // Initialize an array to store product IDs
      let variationIds  = {}; // Store variations ids
      let variationData = []; // Store the Variations data

      // Loop through each addon item
      addon_item_list.each(function () {

          var product_id   = $(this).attr('data-product-id');
          var variation_id = $(this).attr('data-variation-id');
          var quantity     = parseInt( $(this).find('.cross-sell-quantity').val() );

          if( quantity < 1 ){
            quantity = 1;
            $(this).find('.cross-sell-quantity').val(1)
          }

          // We need to update this for the product price calculation 
          $(this).attr('data-product-quantity', quantity);

          // Add product ID to the array if it's not already present
          if (product_id && !productIds.includes(product_id)) {
              productIds.push(product_id);
          }

          if ( variation_id && !variationIds[product_id]) {
            variationIds[product_id] = [];
          }

          // Add Variation id to array if not already present
          if ( variation_id && !variationIds[product_id].includes(variation_id)) {
            variationIds[product_id].push(variation_id);
          }

          let existingEntry = variationData.find(item => item.product_id == product_id && item.variation_id == variation_id);

          if (existingEntry) {
              existingEntry.quantity = parseInt( existingEntry.quantity ) + parseInt( quantity );
          } else if ( quantity > 0) {
              // Add new entry if variation does not exist
              variationData.push({ product_id, variation_id, quantity: parseInt( quantity ) });
          }
      });
      
     /* console.log(productIds);
      console.log(variationIds);
      console.log(variationData);*/

      // Set the collected product IDs in the cross-sell-checkbox
      $('.cross-sell-checkbox').val(productIds.join(','));
      $(".cross_sell_variations_ids").val(JSON.stringify(variationIds));
      $(".cross_sell_variations").val(JSON.stringify(variationData));  // Update the hidden input field

    },

    getAddonProductData: ( product_id ) => {

      const addonProducts = $(document).find('.addon-product');

      if (!product_id || !addonProducts.length) return $();

      const addon_product = addonProducts.filter((index, addonProduct) => 
          $(addonProduct).attr('data-product-id') === String(product_id)
      );

      return addon_product.length ? $(addon_product) : $();
    },
    
    /*
     * @ Find variation
     */
    findMatchingVariation: function ( $addonProduct, selectedAttributes ) {
      return $addonProduct
        .find(".cross-sell-variations")
        .data("variations")
        ?.find(function (variation) {
          return Object.keys(selectedAttributes).every(function (attribute) {
            return variation.attributes[attribute] === selectedAttributes[attribute] || variation.attributes[attribute] === "";
          });
        });
    }
  };

  UPSELL_PRODUCTS.init();
});


function initializeCrossSellVariations() {
  jQuery(function ($) {

    $(".addon-product").each(function () {
      /*
       * @ All componets
       */
      var $addonProduct = $(this);
      var productId = $addonProduct.data("product-id");

      //Product fields
      var $variationInput = $addonProduct.find(".cross_sell_variation_id");
      var $addonsGallery = $(".addons-gallery");
      var $addonsGalleryClose = $(".addons-gallery-close");

      // Handle clicking on the product image
      $addonProduct
        .find(".addon-product-image")
        .off("click")
        .on("click", function () {
          var variationId = $variationInput.val();
          var finalProductId = variationId ? variationId : productId;

          $addonsGallery.parent().addClass("gallery-is-visible");

          if (finalProductId) {
            console.log("Image clicked - Triggering update for product:", finalProductId);
            window.dispatchEvent(new CustomEvent("crossSellVariationChange", { detail: { variation_id: finalProductId } }));
          } else {
            console.warn("No valid product or variation ID found for this addon-product.");
          }
        });

      $addonsGalleryClose.on("click", function () {
        $addonsGallery.parent().removeClass("gallery-is-visible");
      });
    });
  });
}

// Run function on page load
initializeCrossSellVariations();

// Reinitialize when upsell products are dynamically updated
window.addEventListener("crossSellProductsUpdated", function () {
  console.log("Reinitializing cross-sell variations...");
  initializeCrossSellVariations();
});