// Array to keep track of all Swiper instances
window.swiperInstances = []; // Attach to window

// Initializes the product gallery with Swiper.js
function AttributeImagesSwiper() {
  // Clean up old Swiper instances before reinitializing
  window.swiperInstances.forEach((swiper) => swiper.destroy(true, true));
  window.swiperInstances = []; // Clear the instances array

  const attributeSwipers = document.querySelectorAll(".bowe-swatches.swatches-type-images.swiper");

  attributeSwipers.forEach((swiperContainer) => {
    // Initialize the Swiper for each element
    const swiperInstance = new Swiper(swiperContainer, {
      slidesPerView: "auto",
      freeMode: true,
      spaceBetween: 7,
      mousewheel: {
        enabled: true,
      },
      navigation: {
        nextEl: swiperContainer.querySelector(".swiper-button-next"), // get the next button within the swiper container
        prevEl: swiperContainer.querySelector(".swiper-button-prev"), // get the prev button within the swiper container
      },
      breakpoints: {
        777: {
          mousewheel: {
            enabled: false,
          },
        },
      },
      preventClicks: false, // Allow clicks even while swiping
    });

    // Store the instance so we can destroy it later if needed
    window.swiperInstances.push(swiperInstance);

    // Check if a swatch is selected and scroll to it
    window.scrollToSelectedAttribute(swiperContainer, swiperInstance);
  });
}

// Scroll to the selected swatch if it is not visible
window.scrollToSelectedAttribute = function (swiperContainer, swiperInstance) {
  const selectedSwatch = swiperContainer.querySelector(".bowe-swatches-swatch.selected");

  if (selectedSwatch) {
    const swatchIndex = [...swiperContainer.querySelectorAll(".swiper-slide")].indexOf(selectedSwatch);

    if (swatchIndex !== -1) {
      swiperInstance.slideTo(swatchIndex, 300, false); // Smooth scroll to the selected slide
    }
  }
};

// Expose a function on the `window` object to allow reinitialization from other files
window.reinitAttributeSwipers = function () {
  AttributeImagesSwiper();
};

/*
 * Variants slidein
 */
function swatchesVariantsSlideIn() {
  const swatchesBoxVariants = document.querySelector(".bowe-swatches.swatches-type-variants-slide-in");
  if (!swatchesBoxVariants) return;

  const elmToTrigger = document.querySelector(".trigger.color-sidebar");

  swatchesBoxVariants.addEventListener("click", () => {
    console.log(elmToTrigger, swatchesBoxVariants, "click");
    elmToTrigger.click();
  });
}

// Initialize the product gallery when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", () => {
  AttributeImagesSwiper();
  //swatchesVariantsSlideIn();
});
