function slideIns() {
  const slideIns = document.querySelectorAll(".slider-in-wrapper");

  if (!slideIns.length) return null;

  slideIns.forEach((slideIn) => {
    const toggle = slideIn.querySelector(".trigger");
    if (!toggle) return;

    /*if (slideIn.classList.contains("size")) {
      let informationTab = document.querySelector(".slider-in-wrapper.information");
      toggle.addEventListener("click", (e) => {
        e.preventDefault();
        informationTab.querySelector(".trigger").click();
        //informationTab.querySelectorAll(".tabs-tab .tabs-tab-head")[0]?.click();
        //const tabThreeElement = document.querySelector('[data-key="tab_three"]');
      });
      return;
    }*/

    //const popupWrapper = popup.querySelector(".popup");

    const bgColor = slideIn.querySelector(".sidebar-slide-bg-color");
    const close = slideIn.querySelector(".sidebar-slide-inner-close");
    const closeButton = slideIn.querySelector(".close-popup");
    const closeButton2 = slideIn.querySelector(".close-popup-2");

    [toggle, close, bgColor, closeButton, closeButton2].forEach((item) => {
      item?.addEventListener("click", (e) => {
        e.preventDefault();
        //bgColor.classList.toggle("active");
        //close.classList.toggle("active");
        document.body.classList.toggle("stop-scroll");
        slideIn.classList.toggle("active");
      });
    });
  });
}

function tabs() {
  const tabs = document.querySelectorAll(".tabs-tab");
  if (!tabs.length) return;

  tabs.forEach((tab, index) => {
    const tabHead = tab.querySelector(".tabs-tab-head");
    tabHead.addEventListener("click", () => {
      tab.classList.toggle("active");
    });
  });

  /*if (sizeTab) {
    const tabWithSize = ref.current.querySelector(".tabs-tab.size");
    tabWithSize?.classList?.add("active");
  }*/
}

// Initialize the product gallery when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", () => {
  slideIns(); // Initialize gallery for simple products and initial load
  tabs();
});
