import "./_modules/add-to-cart";
import "./_modules/swatches";
import "./_modules/collections";
import "./_modules/product-quantity";
import "./_modules/gallery";
import "./_modules/floating-bar";
import "./_modules/upsell-products";
import "./_modules/upsell-product-ajax";

import "./_modules/slide-ins";
import "./_modules/remember-url";
import "./_modules/variation-attributes";
import "./_modules/color-sidebar";
import "./_modules/found_variation";
import "./_modules/measurement";
import "./_modules/custom_cut_panel";
/*
jQuery(function ($) {
  $(".bolt_ajax_add_to_cart").click(function (event) {
    event.preventDefault();

    const productId = $('input[name="product_id"]').val();
    const variationId = $(".variation_id").val(); // Get variation ID if available
    const quantity = 2; // Adjust as needed or dynamically fetch from a quantity input

    const data = {
      product_id: productId,
      quantity: quantity,
    };

    // Include variation_id if it exists
    if (variationId) {
      data.variation_id = variationId;
    }

    $.ajax({
      type: "POST",
      url: wc_add_to_cart_params.wc_ajax_url.toString().replace("%%endpoint%%", "add_to_cart"),
      data: data,
      dataType: "json",
      beforeSend: function (xhr) {
        // Set the button loading state
        $(".bolt_ajax_add_to_cart").addClass("loading");
      },
      complete: function () {
        // Remove the button loading state
        $(".bolt_ajax_add_to_cart").removeClass("loading");
      },
      success: function (res) {
        if (res.error) {
          console.error("Error adding to cart:", res.error);
        } else {
          // Trigger WooCommerce's "added_to_cart" event for fragments/cart updates
          $(document.body).trigger("added_to_cart", [res.fragments, res.cart_hash]);
        }
      },
      error: function (xhr, status, error) {
        console.error("AJAX error:", error);
      },
    });
  });
});
*/
