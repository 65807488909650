// Function to handle collection selection and filtering
function collections() {
  const collectionForms = document.querySelectorAll(".wc-form-collections");

  collectionForms.forEach((collectionForm) => {
    const clearButton = collectionForm.querySelector(".wc-form-attributes-clear"); // Get the clear button
    const selectedAttributeSpan = collectionForm.querySelector(".selected-attribute"); // Get the span for the selected collection
    const radioButtons = collectionForm.querySelectorAll("input[type='radio']"); // Only radio buttons

    // Listen for 'change' event on each input (radio button)
    radioButtons.forEach((button) => {
      button.addEventListener("change", () => {
        const selectedKey = button.id; // Get the selected collection's ID
        const selectedName = button.nextElementSibling.textContent; // Get the label (collection name)

        // Update the selected collection name in the span
        selectedAttributeSpan.textContent = selectedName;

        // Enable the clear button when a collection is selected
        clearButton.removeAttribute("disabled");

        // Handle filtering the swiper slides based on the selected collection
        filterSwiperSlides(collectionForm, selectedKey);
      });
    });

    // Clear button event listener
    clearButton.addEventListener("click", () => {
      clearSelectedCollection(collectionForm);
    });
  });
}

// Function to filter swiper slides based on the selected collection
function filterSwiperSlides(collectionForm, selectedKey) {
  const wcForm = collectionForm.closest(".wp-block-bowe-woocommerce-add-to-cart-form");
  if (!wcForm) return; // Exit if no parent form is found

  const swiperSlides = wcForm.querySelectorAll(".bowe-swatches.swiper .swiper-slide");

  // Show only slides matching the selected collection and hide the rest
  swiperSlides.forEach((slide) => {
    if (slide.classList.contains(`collection-${selectedKey}`)) {
      slide.classList.remove("hidden");
    } else {
      slide.classList.add("hidden");
    }
  });

  // Reinitialize Swiper after filtering
  if (typeof window.reinitAttributeSwipers === "function") {
    window.reinitAttributeSwipers();
  } else {
    console.error("Swiper reinit function not found");
  }
}

// Function to clear the selected collection and reset the form
function clearSelectedCollection(collectionForm) {
  const clearButton = collectionForm.querySelector(".wc-form-attributes-clear"); // Get the clear button
  const selectedAttributeSpan = collectionForm.querySelector(".selected-attribute"); // Get the span for the selected collection
  const radioButtons = collectionForm.querySelectorAll("input[type='radio']");

  // Clear the selected attribute span text
  selectedAttributeSpan.textContent = "";

  // Uncheck all radio inputs
  radioButtons.forEach((button) => {
    button.checked = false;
  });

  // Reset swiper slides visibility (show all slides)
  const wcForm = collectionForm.closest(".wp-block-bowe-woocommerce-add-to-cart-form");
  const swiperSlides = wcForm.querySelectorAll(".bowe-swatches.swiper .swiper-slide");

  swiperSlides.forEach((slide) => {
    slide.classList.remove("hidden");
  });

  // Disable the clear button again
  clearButton.setAttribute("disabled", "disabled");

  // Reinitialize Swiper after clearing
  if (typeof window.reinitAttributeSwipers === "function") {
    window.reinitAttributeSwipers();
  } else {
    console.error("Swiper reinit function not found");
  }
}

// Initialize collections filtering when the DOM is ready
document.addEventListener("DOMContentLoaded", () => {
  collections();
});
