document.addEventListener("DOMContentLoaded", function () {
  const customCut = document.querySelector(".wc-custom-cut");

  if (!customCut) return;
  const addToCartLink = customCut.querySelector(".wc-form-add-to-cart");
  const heightInput = customCut.querySelector("#custom-height");
  const quantityInput = customCut.querySelector("#custom-quantity");
  const dangerHelpText = customCut.querySelector(".danger-help-text");

  // When inputs change, update the href attributes with correct data
  function updateAddToCartLink() {
    const customHeight = parseInt(heightInput.value, 10);
    const quantity = parseInt(quantityInput.value, 10);

    // Validate the custom height input
    if (isNaN(customHeight) || customHeight < 30) {
      dangerHelpText.classList.add("visible");
      addToCartLink.classList.add("no-input");
      return;
    } else {
      dangerHelpText.classList.remove("visible");
      addToCartLink.classList.remove("no-input");

      // Update the link's data attributes with the quantity and custom height
      addToCartLink.setAttribute("data-quantity", quantity);
      addToCartLink.setAttribute("data-custom-height", customHeight);
    }
  }

  // Listen for changes on the height and quantity inputs
  heightInput.addEventListener("input", updateAddToCartLink);
  quantityInput.addEventListener("input", updateAddToCartLink);

  // Ajax Add to Cart on click
  addToCartLink.addEventListener("click", function (event) {
    event.preventDefault();

    // Get values from the link's data attributes
    //const productId = this.getAttribute("data-product_id");
    //const quantity = this.getAttribute("data-quantity");
    const customHeight = this.getAttribute("data-custom-height");
    ///const requiredProductId = this.getAttribute("data-required-id");

    if (!customHeight || customHeight < 30) {
      dangerHelpText.classList.add("visible");
      return;
    }

    // Open the cart by calling the window.cart function
    window?.cart();
  });
});
