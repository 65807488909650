jQuery(document).ready(function ($) {
  "use strict";

  // Function to update swatches based on the selected option
  function updateSwatches(select_id, selectedValue) {
    var $swatches = $('.bowe-swatches[data-attr-name="' + select_id + '"]');
    $swatches.find(".bowe-swatches-swatch").each(function () {
      var $this = $(this);
      if ($this.data("value") === selectedValue) {
        $this.addClass("selected");
      } else {
        $this.removeClass("selected");
      }
    });
  }

  // Function to disable unavailable swatches
  function disableUnavailableSwatches(select_id) {
    var $select = $("#" + select_id);

    var availableValues = $select
      .find("option:enabled")
      .map(function () {
        return this.value;
      })
      .get();

    var $swatches = $('.bowe-swatches[data-attr-name="' + select_id + '"]');
    $swatches.find(".bowe-swatches-swatch").each(function () {
      var $this = $(this);
      if (!availableValues.includes($this.data("value"))) {
        $this.addClass("disabled");
      } else {
        $this.removeClass("disabled");
      }
    });
  }

  function updateLabel(select_id) {
    // Select the element by the ID passed in
    var $select = $("#" + select_id);

    // Get the text of the selected option
    var selectedText = $select.find("option:selected").text();

    // Find the closest parent wrapper (with .hidden-select-field) and then find the corresponding label
    var $selectWrapper = $select.closest(".hidden-select-field").prev(".wc-form-label");

    // Find the .selected-attribute element inside the wrapper and update its inner HTML with the selected text
    $selectWrapper.find(".selected-attribute").html(selectedText);
  }

  // Listen for swatch clicks
  $(".bowe-swatches-swatch").on("click", function (e) {
    e.preventDefault();

    let $this = jQuery(this);
    let select_id = $this.closest(".bowe-swatches").attr("data-attr-name");
    let selectedValue = $this.data("value");

    var $select = jQuery("#" + select_id);
    if ($select.length) {
      $this.addClass("selected").siblings().removeClass("selected");
      $select.val(selectedValue).trigger("change");
    }
  });

  // Listen for changes to the select field
  $("select").on("change", function () {
    var $select = $(this);
    var select_id = $select.attr("id");
    var selectedValue = $select.val();

    updateSwatches(select_id, selectedValue);
    updateLabel(select_id);

    disableUnavailableSwatches(select_id);
  });

  // Initial disable check for each select
  $("select").each(function () {
    disableUnavailableSwatches(this.id);
  });

  // Run updateLabel on page load for each select
  $("select").each(function () {
    var select_id = $(this).attr("id");
    updateLabel(select_id); // Ensure labels are updated on page load
  });

  // You can add additional code here as needed
});
