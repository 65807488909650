// Initializes the product gallery with Swiper.js
function ProductGallery(selector) {
  let gallery = document.querySelector(`${selector} .swiper`);
  let galleryWrapper = gallery.parentNode;

  if (!galleryWrapper) return; // If no gallery exists, exit

  let thumbsExist = !!galleryWrapper.querySelector(".thumbnails");
  let thumbnails = undefined;

  // Initialize the thumbnail slider if thumbnails exist
  if (thumbsExist) {
    let thumbs = galleryWrapper.querySelector(".thumbnails");
    thumbnails = new Swiper(thumbs, {
      slidesPerView: "auto",
      spaceBetween: 10,
      freeMode: true,
      watchSlidesProgress: true,
    });
  }

  // Initialize the primary product image slider
  let primary = new Swiper(gallery, {
    spaceBetween: 16,
    navigation: {
      nextEl: gallery.querySelector(".swiper-button-next"), // Scoped to this gallery
      prevEl: gallery.querySelector(".swiper-button-prev"), // Scoped to this gallery
    },
    thumbs: thumbsExist ? { swiper: thumbnails } : undefined, // Link thumbnails if they exist
    pagination: {
      el: gallery.querySelector(".swiper-pagination"), // Scoped to this gallery
      type: "fraction",
    },
    slideToClickedSlide: true, // Allow sliding to clicked thumbnail

    on: {
      slideChange(_swiper) {
        _swiper.el?.querySelectorAll("video").forEach(function (video) {
          video.pause();
        });

        let activeSlide = _swiper.slides[_swiper.activeIndex];
        const video = activeSlide.querySelector("video");

        if (video) {
          // Check if the video has a data-src attribute
          const videoSource = video.querySelector("source[data-src]");
          if (videoSource) {
            // If data-src attribute exists, set its value as the src attribute and remove data-src
            videoSource.src = videoSource.getAttribute("data-src");
            videoSource.removeAttribute("data-src");

            // Must load the video after changing the source
            video.load();
          }

          setTimeout(() => {
            video.play();
          }, 250);
        }
      },
    },
  });
}

async function fetcherImages(variationId) {
  const queryString = new URLSearchParams({ variant_id: variationId }).toString();
  const baseUrl = window.location.pathname.startsWith("/us") ? "/us" : "";

  const response = await fetch(`${baseUrl}/wp-json/bowe/v1/variant?${queryString}`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
}

// Function to update all galleries when fetching new images
function updateGallery(className, html, variationId) {
  let wrapper = document.querySelector(`${className}`);

  if (!wrapper) return;

  wrapper.querySelectorAll(".wpp__gallery").forEach((gallery) => {
    gallery.classList.add("hidden");
  });

  // Insert the new fetched gallery inside the wrapper
  wrapper.insertAdjacentHTML("beforeend", html);

  // Re-initialize all galleries
  ProductGallery(`${className} .wpp__gallery[data-variant-id="${variationId}"]`);
}

// Event listener for WooCommerce variation change
jQuery(document).ready(function ($) {
  const singleProduct = document.querySelector(".wp-block-bowe-woocommerce-single-product-v2");

  let currentVariationId = singleProduct.getAttribute("data-variation-id"); // ✅ Correct

  $(".variations_form").on("found_variation", function (event, variation) {
    let variation_id = variation.variation_id;
    if (!variation_id) return;

    // If the variation ID is the same as the currently stored one, exit early
    if (variation.variation_id == currentVariationId) {
      console.log("Same variation selected, skipping AJAX request.");
      return; // Prevent unnecessary AJAX request
    }

    let mainGallery = document.querySelector(".main-product-gallery");

    if (!mainGallery) return;

    // Check if the gallery for the selected variant already exists
    let existingGalleries = mainGallery.querySelectorAll(`.wpp__gallery[data-variant-id="${variation_id}"]`);

    if (existingGalleries.length) {
      let mainGalleries = document.querySelectorAll(".main-product-gallery, .main-product-gallery-clone");

      mainGalleries.forEach((galleryWrapper) => {
        let allGalleries = galleryWrapper.querySelectorAll(".wpp__gallery");

        // Hide all galleries
        allGalleries.forEach((gallery) => {
          gallery.classList.add("hidden");
        });

        // Show only the matching gallery
        let targetGallery = galleryWrapper.querySelector(`.wpp__gallery[data-variant-id="${variation_id}"]`);
        if (targetGallery) targetGallery.classList.remove("hidden");
      });

      return;
    }

    // Fetch new images only if they are not already loaded
    fetcherImages(variation_id)
      .then((data) => {
        if (data.html) {
          updateGallery(".main-product-gallery", data.html, variation_id);
          updateGallery(".main-product-gallery-clone", data.html, variation_id); // Apply to clone separately
        }
      })
      .catch((error) => {
        console.error("Error fetching variant images:", error);
      });
  });
});

function ProductGalleryClone() {
  let cloneGallery = document.querySelector(".main-product-gallery-clone");
  if (!cloneGallery) return;
  ProductGallery(".main-product-gallery-clone .wpp__gallery");
}

window.addEventListener("crossSellVariationChange", function (event) {
  var variation = event.detail;
  var variation_id = variation.variation_id;

  console.log("Gallery update triggered for variation ID:", variation_id);

  let mainGallery = document.querySelector(".addons-gallery"); // Adjust based on your gallery selector
  if (!mainGallery) return;

  // Check if the gallery for the selected variant already exists
  let existingGalleries = mainGallery.querySelectorAll(`.wpp__gallery[data-variant-id="${variation_id}"]`);

  if (existingGalleries.length) {
    mainGallery.querySelectorAll(".wpp__gallery").forEach((gallery) => {
      gallery.classList.add("hidden");
    });

    let targetGallery = mainGallery.querySelector(`.wpp__gallery[data-variant-id="${variation_id}"]`);
    if (targetGallery) targetGallery.classList.remove("hidden");

    return;
  }

  // Fetch new images only if they are not already loaded
  fetcherImages(variation_id)
    .then((data) => {
      if (data.html) {
        updateGallery(".addons-gallery", data.html, variation_id);
      }
    })
    .catch((error) => {
      console.error("Error fetching variant images:", error);
    });
});

// Initialize the product gallery when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", () => {
  ProductGallery(".main-product-gallery .wpp__gallery");
  ProductGalleryClone();
  ProductGallery(".addons-gallery");
});
