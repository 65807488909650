/*
 * Add to cart code
 */

jQuery(document).ready(function ($) {
  // Select the .ajax_add_to_cart button using jQuery
  var $addToCart = $(".ajax_add_to_cart.product-add-to-cart");

  $addToCart.click(() => {
    // If variable product
    var $variantId = $("input.variation_id");
    if ($variantId.length > 0 && $variantId.val().length > 0) {
      // Set the data-product_id attribute to the value of #variation_id
      $addToCart.attr("data-product_id", $variantId.val());
    }

    // Open the cart by calling the window.cart function
    window?.cart();
  });

  // Listen for the found_variation event on the variations form
  $(".variations_form").on("found_variation", function (event, variation) {
    // This event is triggered when a variation is found based on the selections.
    // The `variation` object contains all the details about the variation, including its ID.
    //console.log("Variation ID:", variation.variation_id);

    // Example: Set this variation ID somewhere, e.g., in a hidden input or data attribute.
    $addToCart.attr("data-variation_id", variation.variation_id);
  });
});

/**
 * Handles adding products to the WooCommerce cart via AJAX.
 * Designed for use on single product pages, including variable products.
 *
 * @since 1.0.0
 */
jQuery(function ($) {
  "use strict";

  // Select all add-to-cart buttons within the single product page template
  const elements = [...document.querySelectorAll(".wp-block-bowe-woocommerce-single-product-v2 .cart")];

  // Iterate over each element and attach the click event listener
  elements.forEach((el) => {
    el.addEventListener("submit", (event) => {
      event.preventDefault();
      singleProductAddToCart($(el), event);
    });
  });

  /**
   * Adds a product to the WooCommerce cart using AJAX.
   * Handles button states, WooCommerce events, and error handling.
   *
   * @param {jQuery} wrapper - The jQuery wrapper for the clicked element.
   * @param {Event} event - The triggering event.
   */
  function singleProductAddToCart(wrapper, event) {
    if (!$) return;

    var form = wrapper.closest("form");

    var button = form.find("button.single_add_to_cart_button");

    var formUrl = $(form)[0].action;

    if (typeof formUrl !== "string") {
      form.submit();

      return;
    }

    var formMethod = form.attr("method");

    if (typeof formMethod === "undefined" || formMethod == "") {
      formMethod = "POST";
    }

    var formData = new FormData(form[0]);
    formData.append(button.attr("name"), button.val());

    const quantity = [...formData.entries()].reduce((total, current) => total + (current[0].indexOf("quantity") > -1 ? parseInt(current[1], 10) : 0), 0);

    if (quantity === 0) {
      // return
    }

    button.removeClass("added");
    button.addClass("loading");

    // Trigger event.

    $(document.body).trigger("adding_to_cart", [
      button,
      [...formData.entries()].reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {}),
    ]);

    const url = new URL(formUrl);
    const searchParams = new URLSearchParams(url.search);
    searchParams.append("bolt_add_to_cart", "yes");

    if (window.ct_customizer_localizations) {
      searchParams.set("wp_customize", "on");
    }

    url.search = searchParams.toString();

    formUrl = url.toString();

    console.log("CLICKED here", formUrl);

    fetch(formUrl, {
      method: formMethod,
      body: formData,

      /*
			cache: false,
			contentType: false,
			processData: false,
			*/
    })
      .then((r) => r.json())
      .then(({ success, data }) => {
        if (!success) {
          return;
        }

        const { notices, fragments, cart_hash } = data;

        const errorSelector = ".woocommerce-error, .wc-block-components-notice-banner.is-error";

        const div = document.createElement("div");

        div.innerHTML = notices;

        let error = div.querySelector(errorSelector);

        if (error && error.innerHTML.length > 0) {
          let notices = document.querySelector(".woocommerce-notices-wrapper");

          if (notices.querySelector(errorSelector)) {
            notices.querySelector(errorSelector).remove();
          }

          if (notices) {
            notices.appendChild(error);
          }

          return;
        }

        $(document.body).trigger("added_to_cart", [fragments, cart_hash, button]);

        window?.cart();
      })
      .catch(() => button.removeClass("loading"))
      .finally(() => button.removeClass("loading"));
  }
});
