document.addEventListener('DOMContentLoaded', function () {
	const heightInput = document.getElementById('room-height');
	const widthInput = document.getElementById('room-width');
	const totalDisplay = document.getElementById('total-panels');
	const calculatedPanels = document.getElementById('calculated-panels');
	const qtyInput = document.querySelector('.main-product-quantity input.qty'); // Select the WooCommerce quantity input

	// Quick return if any required element is missing
	if (
		!heightInput ||
		!widthInput ||
		!totalDisplay ||
		!calculatedPanels ||
		!qtyInput ||
		!measurementEl
	)
		return;

	// Retrieve data from `data-*` attributes
	const measurementUnit = document.querySelector(
		'.wc-form-measurement.wc-custom-measurement'
	).dataset.unit;
	const covers =
		parseFloat(
			document.querySelector('.wc-form-measurement.wc-custom-measurement')
				.dataset.covers
		) || 1;

	// Function to calculate required panels
	function calculatePanels() {
		const roomHeight = parseFloat(heightInput.value) || 0;
		const roomWidth = parseFloat(widthInput.value) || 0;
		let panelsNeeded = 0;

		// Convert if measurement is in inches
		if (measurementUnit === 'in') {
			panelsNeeded = roomHeight * roomWidth * 0.00694444;
		} else {
			panelsNeeded = (roomHeight * roomWidth) / 10000;
		}

		console.log('Coverage value:', covers);

		// Divide by coverage value
		// Divide by coverage value
		panelsNeeded /= covers;

		// Normalize floating-point precision to avoid inaccuracies
		panelsNeeded = parseFloat(panelsNeeded.toFixed(10));
		//console.log(panelsNeeded, roomHeight, roomWidth, (roomHeight * roomWidth) / 10000);
		return Math.ceil(panelsNeeded);
	}

	// Function to update the total display
	function updateTotalDisplay() {
		const panelsNeeded = calculatePanels();
		if (panelsNeeded > 0) {
			calculatedPanels.innerText = panelsNeeded;
			totalDisplay.classList.add('visible');
		} else {
			calculatedPanels.innerText = '';
			totalDisplay.classList.remove('visible');
		}
	}

	// Function to update the quantity input when the total is clicked
	function updateQuantity() {
		const panelsNeeded = calculatePanels();
		if (panelsNeeded > 0) {
			qtyInput.value = panelsNeeded; // Set the qty input to the calculated panels
			window.update_product_display_price();
		}
	}

	// Event listeners for input changes to recalculate panels
	heightInput.addEventListener('input', updateTotalDisplay);
	widthInput.addEventListener('input', updateTotalDisplay);

	// Event listener for clicking on the total display to update the quantity
	totalDisplay.addEventListener('click', updateQuantity);
});
