jQuery(document).ready(function ($) {
  /*
   * Upsell container
   */
  const singleProduct = document.querySelector(".wp-block-bowe-woocommerce-single-product-v2");
  let ProductCrossSellMarkup = singleProduct.querySelector("#product-cross-sell-markup");
  let addOnsTrigger = singleProduct.querySelector(".add-ons-slider");

  let currentVariationId = singleProduct.getAttribute("data-variation-id"); // ✅ Correct

  // When a variation is selected
  $(document).on("found_variation", function (event, variation) {
    console.log(variation);

    // If the variation ID is the same as the currently stored one, exit early
    if (variation.variation_id == currentVariationId) {
      console.log("Same variation selected, skipping AJAX request.");
      return; // Prevent unnecessary AJAX request
    }

    /*
     * @ Reset selected products list's
     */
    $(".checked-products-list").empty();
    $(".add-ons-selected-list-placeholder").empty().removeClass("visible"); // Reset and hide add-ons-selected-list

    //if (variation.upsell_ids && variation.upsell_ids.length > 0) {
    //var upsellIds = variation.upsell_ids.join(",");

    // Determine the base URL based on the current location
    const baseUrl = window.location.pathname.startsWith("/us") ? "/us" : "";

    // Fetch the upsell products HTML via AJAX GET request
    $.ajax({
      url: `${baseUrl}/wp-json/woodupp-wc/product-page-addons`,
      method: "GET",
      data: {
        //include: upsellIds,
        variation_id: variation?.variation_id,
        parent_id: variation?.parent_id,
      },
      success: function (response) {
        if (response.html) {
          /*
           * @Upsell products
           */

          if (response.html && ProductCrossSellMarkup) {
            if (addOnsTrigger.classList.contains("hidden")) {
              addOnsTrigger.classList.remove("hidden");
            }

            //console.log("HERE");

            ProductCrossSellMarkup.innerHTML = response.html;
            window?.updateQuantityButtons();
            // Dispatch a custom event to trigger reinitialization
            //console.log('window.dispatchEvent(new Event("crossSellProductsUpdated"));');
            window.dispatchEvent(new Event("crossSellProductsUpdated"));

            showHideFilters();
            
          }

          /*
           * If no variations upsell found then hide the product cross
           */
          if (!variation?.upsell_html_markup && !addOnsTrigger?.classList.contains("hidden")) {
            //ProductCrossSellMarkup.classList.add("hidden");
          }
        }
      },
      error: function () {
        console.error("Failed to load upsell products.");
      },
    });
    //}
  });

  function showHideFilters(){
    const $sidebar = $('.sidebar-slide-inner-filter');
    const $addons = $('.addon-product');

    let hasTwo = false;
    let allOne = true;

    $addons.each(function() {
      const source = $(this).data('source'); // gets "one" or "two"
      if (source === 'two') {
        hasTwo = true;
      }
      if (source !== 'one') {
        allOne = false;
      }
    });

    if (hasTwo) {
      if (!$sidebar.hasClass('show')) {
        $sidebar.addClass('show');
      }
      $sidebar.removeClass('hidden');
    } else if ($addons.length === 0 || allOne) {
      if (!$sidebar.hasClass('hidden')) {
        $sidebar.addClass('hidden');
      }
      $sidebar.removeClass('show');
    }
  }
});
