jQuery(document).ready(function ($) {
	const singleProduct = document.querySelector(
		'.wp-block-bowe-woocommerce-single-product-v2'
	);
	let currentVariationId = singleProduct.getAttribute('data-variation-id'); // ✅ Correct

	/*
	 * @ Elements
	 */
	let priceMain = singleProduct.querySelector(
		'.wc-form-price-wrapper .wc-form-price'
	);

	let priceBottom = singleProduct.querySelector(
		'.wc-form-total .wc-form-total-price'
	);
	let shippingNotice = singleProduct.querySelector(
		'.wc-form-shipping-placeholder'
	);
	//let priceBottom = singleProduct.querySelector(".wc-form-total .wc-form-total-price");

	let availability_html = singleProduct.querySelector('.availability_html');

	/*
	 * @Floating
	 */
	let floatingImage = singleProduct.querySelector(
		'.floating-add-to-cart-image'
	);
	let floatingPrice = singleProduct.querySelector(
		'.floating-add-to-cart-content-price'
	);
	let floatingName = singleProduct.querySelector(
		'.floating-add-to-cart-nice-name'
	);
	let floatingAddCart = singleProduct.querySelector('.floating-add-to-cart');

	// Listen for the found_variation event on the variations form
	$('.variations_form').on('found_variation', function (event, variation) {
		let variation_id = variation.variation_id;
		if (!variation_id) return;

		//console.log(variation);

		if (variation?.is_in_stock === false) {
			//Set out of stuck
		}

		// If the variation ID is the same as the currently stored one, exit early
		/* if (variation.variation_id == currentVariationId) {
      console.log("Same variation selected, skipping AJAX request.");
      return; // Prevent unnecessary AJAX request
    }*/

		priceMain.innerHTML = variation?.price_html;
		priceBottom.innerHTML = variation?.display_price_html;

		if (shippingNotice) {
			shippingNotice.innerHTML = variation?.shipping_days_show_top;

			if (variation?.availability_html) {
				shippingNotice.innerHTML += variation?.availability_html;
			}
		}

		/*
		 * @Floating
		 */

		if (floatingPrice) {
			floatingPrice.innerHTML = variation?.display_price_html;
			floatingName.innerHTML = variation?.nice_format_name;
			floatingImage.src = variation?.image?.gallery_thumbnail_src;
		}

		/*
		 * @ In stock
		 */
		if (variation?.is_in_stock && floatingAddCart) {
			floatingAddCart.classList.remove('disabled');
		}

		/*
		 * @ Out of stock
		 */
		if (!variation?.is_in_stock && floatingAddCart) {
			floatingAddCart.classList.add('disabled');
		}

		/*
		 * Updating
		 */
		$('.wc-form-product-overview-type-line').each(function () {
			let attributeKey = $(this).data('attribute-key'); // Get the attribute key from data-attribute-key

			if (
				attributeKey &&
				variation.attributes['attribute_' + attributeKey]
			) {
				let textToDisplay =
					variation.attributes['attribute_' + attributeKey];

				if (attributeKey === 'pa_colors') {
					textToDisplay = variation?.nice_format_name;
				}
				$(this).find('.line-value').text(textToDisplay);
			}
		});
	});
});
