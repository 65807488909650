document.addEventListener("DOMContentLoaded", function () {
  let hidden = true;
  const floatingBar = document.querySelector(".wc-form-floating-add-to-cart");

  function handleScroll() {
    const currentScrollPos = window.pageYOffset;

    if (currentScrollPos >= 0 && currentScrollPos < 200) {
      floatingBar.classList.add("hidden");
    } else if (currentScrollPos >= 450) {
      floatingBar.classList.remove("hidden");
    }
  }

  window.addEventListener("scroll", handleScroll);

  // Cleanup when the page unloads
  window.addEventListener("beforeunload", () => {
    window.removeEventListener("scroll", handleScroll);
  });
});
