jQuery(document).ready(function ($) {
  /*
    .1 Find the .quantity
    2. if it has parent  enhanced 
    3. main-product-quantity
    .
    */
  // Make the function accessible globally
  window.updateQuantityButtons = function () {
    // Remove previous event handlers to avoid duplicates
    $(".quantity__increment").off("click");

    // For each quantity wrapper inside .quantities
    $(".wp-block-bowe-woocommerce-single-product-v2 .quantity").each(function () {
      let $qtyInput = $(this).find("input.qty");
      let $plus = $(this).find(".plus");
      let $minus = $(this).find(".minus");

      // Increase Quantity
      $plus.on("click", function () {
        var currentVal = parseInt($qtyInput.val());

        //var maxVal = $qtyInput.attr("max") === "" ? Infinity : parseInt($qtyInput.attr("max"));
        var maxVal = $qtyInput.attr("max") === "" || $qtyInput.attr("max") == "Infinity" ? 9999 : parseInt($qtyInput.attr("max")) || 9999;
        var steps = parseInt($qtyInput.attr("data-steps")) || 1; // Use .attr instead of .data

        if (currentVal < maxVal) {
          var new_quantity = currentVal + steps;

          $qtyInput.val(new_quantity).trigger("change");

          update_cover_and_price($(this), new_quantity);

          window.update_product_display_price();
        }
      });

      // Decrease Quantity
      $minus.on("click", function () {
        var currentVal = parseInt($qtyInput.val());
        var minVal = parseInt($qtyInput.attr("min"));
        var steps = $qtyInput.data("steps") ? parseInt($qtyInput.data("steps")) : 1;

        if (currentVal > minVal) {
          var new_quantity = currentVal - steps;

          $qtyInput.val(new_quantity).trigger("change");
          update_cover_and_price($(this), new_quantity);
        }
      });

      $qtyInput.on("input", function () {
        update_cover_and_price($(this), $(this).val());
      });
    });
  };

  window.quantity_product_price_update = function (event) {
    window.update_product_display_price();
  };

  /**
   * Update the cover and price on variation change
   */

  $("form.variations_form").on("found_variation", function (event, variation) {
    window.update_product_display_price();
  });

  /**
   * Window function to update the price and cover with quantity calculation
   */

  window.update_product_display_price = function (enhanced = true) {
    if (enhanced) {
      const quantity_wrapper = $(".quantity-wrapper.enhanced");
      const quantity = quantity_wrapper.find(".quantity__input").val();
      let add_to_cart_btn = quantity_wrapper.nextAll(".add_to_cart_button:first");

      if (quantity_wrapper.length) {
        if (add_to_cart_btn.length) {
          add_to_cart_btn.attr("data-quantity", quantity);
        }

        update_cover(quantity_wrapper, quantity);

        setTimeout(() => {
          var price_wrapper = quantity_wrapper.parent().find(".wc-price");
          const price_attr_wrap = quantity_wrapper.parent().find(".wc-form-total-price");

          update_price(price_wrapper, price_attr_wrap, quantity);
        }, 1);
      }
    }
  };

  /**
   * Old function to update the cover and price
   * Still in use but
   * Need to upgrade this function to also use window.update_product_display_price
   */

  const update_cover_and_price = function (elmnt, quantity) {
    const quantity_parent = elmnt.parents(".quantity");
    const quantity_wrapper = quantity_parent.parents(".quantity-wrapper");
    var price_wrapper = quantity_wrapper.parent().find(".wpp__price-root .wc-price");
    const price_attr_wrap = quantity_wrapper.parent().find(".wc-form-total-price");
    let add_to_cart_btn = "";

    if (quantity_wrapper.length) {
      add_to_cart_btn = quantity_wrapper.nextAll(".add_to_cart_button:first");
    } else {
      add_to_cart_btn = quantity_parent.next().find(".add_to_cart_button:first");
    }

    if (add_to_cart_btn.length) {
      add_to_cart_btn.attr("data-quantity", quantity);
    }

    if ($(quantity_wrapper).hasClass("enhanced")) {
      update_cover(quantity_wrapper, quantity);
    }

    update_price(price_wrapper, price_attr_wrap, quantity);
  };

  /**
   * Function to update the cover with quantity calculation
   * @param {*} quantity_wrapper
   * @param {*} quantity
   */

  function update_cover(quantity_wrapper, quantity) {
    let quantity_unit = $(quantity_wrapper).find(".quantity-unit");

    if (quantity_unit.length) {
      var unit = quantity_unit.data("unit");
      var new_unit = quantity * unit;

      quantity_unit.html(new_unit.toFixed(2));
    }
  }

  /**
   * Function to update the price after calculating with quantity
   * @param {*} price_wrapper
   * @param {*} price_attr_wrap
   * @param {*} quantity
   */

  function update_price(price_wrapper, price_attr_wrap, quantity) {
    // Get the form where variations are being selected
    var form = $("form.variations_form");
    var isVariableProduct = form.length > 0;
    // Get the current variation data from WooCommerce
    var variationData = form.data("product_variations");

    // Get the selected attributes (variations) from the form
    var selectedVariation = form.find(".variation_id").val();

    /**
     * Addon price calculations
     */
    var items = $(".add-ons-selected-list-item");
    var addonTotal = 0;

    if (items.length > 0) {
      items.each(function () { 
        var price    = parseFloat($(this).data("product-price")) || 0;
        var quantity = parseInt($(this).attr("data-product-quantity")) || 0;
        var total    = price * quantity;
        
        addonTotal = parseFloat(addonTotal) + parseFloat(total);
      });
    }

    addonTotal = parseFloat(addonTotal).toFixed(2);

    // If a variation is selected, it will have a variation ID
    if (selectedVariation) {
      // Find the matching variation object
      var variation = variationData.find(function (variation) {
        return variation.variation_id == selectedVariation;
      });

      if (variation) {
        // You can now access the price or any other data
        var price = variation.display_price;

        if (price_wrapper.length) {
          if (!isNaN(price)) {
            // Multiply the price by the quantity
            let new_price = price * quantity;
            new_price = parseFloat(new_price) + parseFloat(addonTotal);

            if (price_attr_wrap.length) {
              var price_attr = price_attr_wrap.attr("data-price-config");
              price_attr = $.parseJSON(price_attr);

              new_price = parseFloat(new_price).toFixed(2);
              console.log(new_price);
              new_price = formatPrice(new_price, price_attr);
            }

            // Optionally, you can update the price display (or handle it elsewhere)
            price_wrapper.html(new_price);
          }
        }
      }
    }

    if (!isVariableProduct) {
      let price = jQuery(".woocommerce-Price-amount")
        .first()
        .text()
        .replace(/[^0-9.]/g, "");

      let calculated_price = parseFloat(price) * quantity;

      calculated_price += addonTotal;

      price_wrapper.html(calculated_price.toFixed(2));
    }

    //Down here
  }

  /**
   * Function to format the price as wp_price
   * @param {*} price
   * @param {*} price_attr
   * @returns int | string
   */
  function formatPrice(price, price_attr) {
    //const currencyPosition  = price_attr.currency_position;
    const decimals = price_attr.decimals;
    const thousandSeparator = price_attr.thousand_separator;
    // const currencySymbol    = price_attr.currency_symbol;
    const decimalSeparator = price_attr.decimal_separator;

    var parts = parseFloat(price).toFixed(decimals).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

    return parts.join(decimalSeparator); //formattedPrice =

    /*switch (currencyPosition) {
          case 'left':
              return currencySymbol + ' ' + formattedPrice;
          case 'right':
              return formattedPrice + ' ' + currencySymbol;
          case 'left_space':
              return currencySymbol + ' ' + formattedPrice;
          case 'right_space':
              return formattedPrice + ' ' + currencySymbol;
          default:
              return formattedPrice;
      }*/
  }

  // Initialize the quantity buttons functionality
  window.updateQuantityButtons();

  // Reinitialize when variations are changed/selected
  $(".variations_form").on("woocommerce_variation_select_change", function () {
    window.updateQuantityButtons();
  });

  $(".single_variation_wrap").on("show_variation", function (event, variation) {
    window.updateQuantityButtons();
  });
});
