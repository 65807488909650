jQuery(document).ready(function ($) {
  function colorSidebar() {
    const $slideVariants = $(".slide-variants");
    const $colorItems = $slideVariants.find(".color-variants-variation");
    const $colorPreviews = $slideVariants.find(".color-selector-image");
    const $selectColor = $slideVariants.find(".close-popup.wp-block-button");
    const $selectField = $('select[name="attribute_pa_colors"]');

    let selectedColorSlug = null;

    /*
     * @ Add click event to each color item
     */
    $colorItems.on("click", function () {
      // Remove 'active' class from all color items
      $colorItems.removeClass("active");

      // Add 'active' class to the clicked item
      $(this).addClass("active");

      // Store the selected color slug
      selectedColorSlug = $(this).data("variant-slug");

      // Hide all color preview images
      $colorPreviews.hide();

      // Show the selected color's preview image
      const selectedIndex = $(this).data("index");
      const $selectedPreview = $(`.color-selector-image[data-index="${selectedIndex}"]`);

      if ($selectedPreview.length) {
        $selectedPreview.show();
      }
    });

    /*
     * @ See more info
     */
    $selectColor.on("click", function (e) {
      e.preventDefault();

      // Find the corresponding option in the select field
      if (selectedColorSlug && $selectField.length) {
        const $matchingOption = $selectField.find(`option[value="${selectedColorSlug}"]`);

        if ($matchingOption.length) {
          // Select the matching option
          $matchingOption.prop("selected", true);

          // Trigger the change event
          $selectField.trigger("change");

          // Optionally, trigger a click on the selected option (though this is uncommon)
          $matchingOption.trigger("click");

          // Scroll to the selected attribute using the global window function
          const swiperContainer = $(".bowe-swatches.swatches-type-images.swiper")[0];
          const swiperInstance = window.swiperInstances.find(
            (swiper) => swiper.el === swiperContainer
          );
          if (swiperContainer && swiperInstance) {
            window.scrollToSelectedAttribute(swiperContainer, swiperInstance);
          }
        }
      }
    });
  }

  // Initialize colorSidebar on document ready
  colorSidebar();
});
