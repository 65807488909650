jQuery(document).ready(function ($) {
  // Listen for the found_variation event on the variations form
  $(".variations_form").on("found_variation", function (event, variation) {
    let variation_id = variation.variation_id;
    if (!variation_id) return;

    // Call the function to update the URL with selected attributes
    updateUrlWithAttributes(variation);
  });

  // Isolated function to update the URL with selected attributes
  function updateUrlWithAttributes(variation) {
    let baseUrl = window.location.href.split("?")[0]; // Get base URL without query params

    // Preserve existing URL parameters
    let existingParams = new URLSearchParams(window.location.search);

    // Construct the query string for selected attributes
    let queryParams = new URLSearchParams();

    // Loop through the variation's attributes and add them to queryParams
    $.each(variation.attributes, function (key, value) {
      if (value) {
        queryParams.set(key, value); // Add attribute key and value to query string
      }
    });

    // Check for additional parameters like "variant" and retain them if they exist
    let customParams = ["variant", "another_param"]; // List additional params to retain
    customParams.forEach(function (param) {
      if (existingParams.has(param)) {
        queryParams.set(param, existingParams.get(param));
      }
    });

    // Construct the new URL with the updated query parameters
    let newUrl = baseUrl + "?" + queryParams.toString();

    // Update the browser's URL without reloading the page
    window.history.pushState(null, null, newUrl);
  }
});
